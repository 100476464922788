import React from "react";

const Keypoints = () => {
    const keypointsValue = [
        {
            id: 1,
            heading: "1. Team Structure",
            description:
                "The number of participants per team must be 5 and the team members' information should be added in the registration. There must be 1 Product Manager or 1 person from GTM as acting PM. There can be at most 3 product developers (frontend and backend developers) and at most 1 QA Engineer. There can be at most 1 Designer. Product Managers and Designers can be shared among multiple teams.",
        },
        {
            id: 2,
            heading: "2. App Count",
            description:
                "You are free to build any number of apps within the deadline, as the number of apps is also part of the evaluation.",
        },
        {
            id: 3,
            heading: "3. Guidelines",
            description:
                "The apps must adhere to certain guidelines for the app to be published officially on AppNest.",
            redirectButton: true,
        },

        {
            id: 4,
            heading: "4. Deadline",
            description:
                "The project must be submitted before 12:00 AM IST on Sunday, 17th March. Any submission after the deadline will not be accepted.",
        },
    ];

    return (
        <div className="keypoints__container">
            <h1 className="keypoints__container--heading">Key Points</h1>
            <div className="keypoints__container__card">
                {keypointsValue.map((keypoint, index) => (
                    <div className="keypoints__container__card--content">
                        <h1>{keypoint.heading}</h1>
                        <p>{keypoint.description}</p>

                        {keypoint?.redirectButton && (
                            <a
                                href="https://surveysparrow.gitbook.io/contribution-guidelines/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <button>Read Guidelines</button>
                            </a>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Keypoints;
