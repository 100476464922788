import React from "react";

const Arrow = () => {
  return (
    <svg
      width="144"
      height="103"
      viewBox="0 0 144 103"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78.853 61.3209C66.8716 58.8383 60.9557 50.0474 64.4041 40.1201C65.2167 37.7789 67.4632 35.186 69.6937 34.2643C72.8696 32.9547 75.3947 35.3286 77.171 38.129C81.7754 45.3878 81.7346 52.9425 78.853 61.3209ZM132.473 29.6668C137.185 34.8483 135.183 43.4852 143.65 46.4634C143.616 44.5248 143.815 43.2982 143.54 42.1912C140.508 29.9632 137.492 17.7306 134.229 5.56461C133.694 3.57068 132.338 0.853879 130.731 0.29823C127.775 -0.725226 126.426 2.03756 125.357 4.53845C122.037 12.3084 118.618 20.0374 115.215 27.8338C120.21 28.8157 120.21 28.8157 127.753 16.6663C131.181 42.7035 111.865 64.0968 86.2724 62.1714C86.604 59.64 87.0109 57.1361 87.2467 54.616C87.9385 47.1933 86.5251 40.3131 81.9899 34.2032C78.6486 29.7041 74.327 27.2222 68.7115 28.2298C62.9321 29.2682 60.2415 33.5896 58.814 38.8806C55.7208 50.3419 60.4293 60.4679 71.2165 65.6677C72.5475 66.3092 73.7764 67.1601 75.5037 68.1801C67.5551 80.9386 57.1348 90.2049 42.9872 94.3008C21.1766 100.616 8.24648 85.8374 3.62707 70.607C3.05672 68.7241 2.83705 66.7347 2.2179 63.5965C-0.337709 67.9237 0.261223 71.4468 0.860951 74.8552C3.23252 88.3172 15.4057 100.069 28.9644 102.097C37.6427 103.394 45.9035 101.957 53.6448 97.8977C65.0327 91.9261 74.3797 83.7094 80.9108 72.4937C81.5539 71.3906 82.2663 70.3285 83.1684 68.8945C95.0224 68.994 106.575 67.5948 116.442 59.7802C126.076 52.1526 128.702 40.7675 132.473 29.6668Z"
        fill="#fffff1"
        fill-opacity="1"
      />
    </svg>
  );
};

export default Arrow;
