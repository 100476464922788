import { useScroll, useTransform, motion } from "framer-motion";
import Home from "./components/home";
import Level from "./components/level";
import { useEffect, useRef } from "react";
import Info from "./components/info";
import Lenis from "@studio-freight/lenis";
import Timeline from "./components/timeline";
import Keypoints from "./components/keypoints";
import GameOver from "./components/game-over";
import { useMediaQuery } from "react-responsive";
import Prizes from "./components/prizes";
import Timer from "./components/timer";
import firstLevelBackground from "./assets/level-background-1.png";
import secondLevelBackground from "./assets/level-background-2.png";
import trees from "./assets/trees.png";


const App = () => {
  const container = useRef(null);
  const { scrollYProgress: containerScroll } = useScroll({
    target: container,
    offset: ["start end", "end start"]
  });
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const timelineRef = useRef(null);
  const isLargeMobileScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const isMediumMobileScreen = useMediaQuery({ query: "(max-width: 440px)" });
  const scaleValues = isLargeMobileScreen ? [200, -250] : [350, -300];
  const y = useTransform(containerScroll, [0, 1], scaleValues);

  useEffect(() => {
    const lenis = new Lenis();

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);
    console.log("Did you expect something to be here? (JK) Happy hacking! ;)")

  }, []);

  return (
    <motion.div ref={container}>
      <Home homeRef={homeRef} aboutRef={aboutRef} timelineRef={timelineRef} />
      <motion.div style={{ y }} ref={aboutRef}>
        <Level
          level={"Level 1"}
          heading={"Investigate the AppTown"}
          isLargeMobileScreen={isLargeMobileScreen}
          fill={3}
          description={
            "A chance to explore new technologies and solve business requirements for SurveySparrow and ThriveSparrow"
          }
          background={firstLevelBackground}
        />
      </motion.div>
      <Info aboutRef={aboutRef} />
      <motion.div style={{ y }}>
        <Level
          level={"Level 2"}
          heading={"Strategize & Conquer"}
          isLargeMobileScreen={isLargeMobileScreen}
          isMediumMobileScreen={isMediumMobileScreen}
          isStanding={true}
          description={
            "Enroll in our upcoming workshops to gain a clear understanding of building apps in AppNest. Stay tuned for announcements."
          }
          background={trees}
        />
      </motion.div>

      <Timeline
        timelineRef={timelineRef}
        isLargeMobileScreen={isLargeMobileScreen}
      />

      <motion.div style={{ y }}>
        <Level
          level={"Level 3"}
          heading={"AppTown for All"}
          isLargeMobileScreen={isLargeMobileScreen}
          isMediumMobileScreen={isMediumMobileScreen}
          isLevelThree
          description={
            "The coding ninjas are here to assist you in resolving your queries. If you prefer direct assistance, our support team is ready to address your concerns at "
          }
          email={"support@surveysparrow.dev"}
          background={secondLevelBackground}

        />
      </motion.div>

      <Timer />
      <Keypoints />
      <Prizes />
      <GameOver homeRef={homeRef} />
    </motion.div>
  );
};

export default App;
