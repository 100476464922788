import React, { useRef } from "react";
import character from "../assets/character.png";
import { useScroll, useTransform, motion } from "framer-motion";

const Timeline = React.forwardRef(({ timelineRef, isLargeMobileScreen }) => {
  const timeLineValues = [
    {
      id: 1,
      day: "Day 1",
      date: "Thursday, March 14th",
      content: [
        "6.00 PM - Enter the Sparcade Adventure",
        "6:30 PM - Game on! Ready, Set, Code!"
      ]
    },
    {
      id: 2,
      day: "Day 2 ",
      date: "Friday, March 15th",
      content: ["4:30 PM - Advantage Task 1"]
    },
    {
      id: 3,
      day: "Day 3 ",
      date: "Saturday, March 16th",
      content: [" 4:30 PM - Advantage Task 2"]
    },

    {
      id: 4,
      day: "Day 4 ",
      date: "Sunday, March 17th",
      content: [
        "12:00 AM - Game Over",
        "9:00 AM - App Inspection - Session 1",
        "2:00 PM - App Inspection - Session 2",
        "6:00 PM - Sparcade Results"
      ]
    }
  ];

  const timelineWrapperRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: timelineWrapperRef,
    offset: ["start end", "end start"]
  });

  const y = useTransform(scrollYProgress, [0, 1], [-140, 1750]);

  return (
    <section class="timeline-alignment" ref={timelineRef}>
      <div class="timeline-wrapper" ref={timelineWrapperRef}>
        <h1 class="timeline-wrapper__heading">Timeline</h1>
        <p class="timeline-wrapper__description">
          A brief about the proceedings.
        </p>
        <div class="timeline-wrapper__container">
          <motion.img
            src={character}
            alt=""
            style={{
              y
            }}
          />
          {timeLineValues.map((time) => (
            <div class="timeline-wrapper__item">
              <p class="timeline-wrapper__item__time">{time.day}</p>
              <p class="timeline-wrapper__item__time">{time.date}</p>
              <div class="timeline-wrapper__item__wrapper">
                <div class="timeline-wrapper__item__wrapper__vertical-line"></div>
                <span class="timeline-wrapper__item__wrapper__dot"></span>
              </div>
              <div className="timeline-wrapper__item__details">
                {time.content.map((content) => (
                  <p class="timeline-wrapper__item__desc">{content}</p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
});

export default Timeline;
