import React from "react";

const Info = ({ aboutRef }) => {
    const textValues = [
        {
            id: 1,
            heading: "Explore AppNest",
            description:
                "The first step in the hackathon is to be familiar with SurveySparrow and ThriveSparrow to build apps and publish them on AppNest. The more aware you are of the features, the better the problem statement will be.",
        },
        {
            id: 2,
            heading: "Hackathon Guidebook",
            description:
                "Peruse the documents for step-by-step instructions on building apps on AppNest.",
            sourceLink: "https://google.com",
        },
        {
            id: 3,
            heading: "Submit and Win Prizes",
            description:
                "Ready to take the spotlight? Submit your app, and let the judging panel work their magic! Your chance to snag a cash prize is just a click away.",
        },
    ];

    return (
        <div className="info__container" ref={aboutRef}>
            <div className="info__container--wrapper">
                {textValues.map((text) => (
                    <div className="info__container--details">
                        <h1>{text.heading}</h1>
                        <p>{text.description}</p>
                        {text.sourceLink ? (
                            <a
                                href="https://surveysparrow.gitbook.io/appnest-hackathon-guidebook"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <button>Read More</button>
                            </a>
                        ) : null}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Info;
