import React, { useRef } from "react";
import Heart from "../assets/heart";
import { motion, useScroll, useTransform } from "framer-motion";
import character from "../assets/jump-char.png";
import fightingCharacter from "../assets/new-character.png";
import river from "../assets/river.png";

const Level = React.forwardRef(
  ({
    level,
    heading,
    description,
    isLargeMobileScreen,
    isMediumMobileScreen,
    email,
    background,
    isStanding,
    isLevelThree
  }) => {
    const textRef = useRef();
    const { scrollYProgress } = useScroll({
      target: textRef,
      offset: ["start end", "end start"]
    });
    const scaleValues = isLargeMobileScreen
      ? [130, -130]
      : isMediumMobileScreen
      ? [120, -120]
      : [200, -400];

    const y = useTransform(scrollYProgress, [0, 1], scaleValues);

    return (
      <div
        className={`level__container`}
        ref={textRef}
        style={{
          backgroundImage: `url(${background})`
        }}
      >
        <img
          src={isStanding ? character : fightingCharacter}
          alt=""
          style={{
            left: isLevelThree ? "84%" : isStanding ? "45%" : "10%"
          }}
          className={
            !isStanding
              ? "level__container--img"
              : "level__container--img-fighting"
          }
        />
        {
          isStanding && <img src={river} alt="" className="level__container--river" />
        }
        <motion.div
          className="level__container__details"
          style={{
            y
          }}
        >
          <h1>{level}</h1>
          <h2>{heading}</h2>
          <p>
            {description}{" "}
            <motion.a
              animate={{
                backgroundSize: ["50%", "100%"]
              }}
              transition={{
                repeat: Infinity,
                duration: 1.5
              }}
              href={`mailto:${email}`}
            >
              {email}
            </motion.a>
          </p>
        </motion.div>
      </div>
    );
  }
);

export default Level;
