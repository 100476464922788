import React from "react";
import bg from "../assets/bg.jpeg";
import logo from "../assets/newlogo.png";
import Sidebar from "./sidebar";
import { motion } from "framer-motion";
import Arrow from "../assets/arrow";
import ResponsiveSideBar from "./ResponsiveSideBar";

const Home = React.forwardRef(({ homeRef, aboutRef, timelineRef }) => {
  return (
    <div
      id="home"
      ref={homeRef ? homeRef : null}
      style={{
        backgroundImage: `linear-gradient(
      to right,
      rgba(0, 0, 0, 0.6) 30%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0.6) 100%
  ), url(${bg})`
      }}
      className="home__container"
    >
      <img src={logo} alt="Logo" className="home__container__logo" />
      <h1 className="home__container__heading">Battle of Apps</h1>
      <p className="home__container__description">
        Push the limits to build innovative custom solutions that extend the
        prowess of SurveySparrow and ThriveSparrow.
      </p>
      <div className="home__container--cta">
        <a
          href="https://sprw.io/stt-t6gGch8qDhoUHPF3rwF64q"
          target="_blank"
          rel="noreferrer"
        >
          <motion.button
            animate={{ scale: [1, 1.3, 1] }}
            transition={{ repeat: Infinity, duration: 0.8 }}
            className="home__container__primary-cta"
          >
            Register
          </motion.button>
        </a>
        <span>
          <Arrow />
        </span>
        <h4>Start the game</h4>
      </div>
      <Sidebar
        aboutRef={aboutRef}
        timelineRef={timelineRef}
        homeRef={homeRef}
      />
      <ResponsiveSideBar
        aboutRef={aboutRef}
        timelineRef={timelineRef}
        homeRef={homeRef}
      />
    </div>
  );
});

export default Home;
