import React from "react";

const Sidebar = React.forwardRef(({ aboutRef, timelineRef, homeRef }) => {
  return (
    <div className="sidebar">
      <nav>
        <a
          href="#"
          onClick={() =>
            homeRef?.current
              ? homeRef.current.scrollIntoView({
                  behavior: "smooth"
                })
              : window.location.replace("/#home")
          }
        >
          Home
        </a>
        <a
          href="#"
          onClick={() =>
            aboutRef?.current
              ? aboutRef.current.scrollIntoView({
                  behavior: "smooth"
                })
              : window.location.replace("/#home")
          }
        >
          About
        </a>
        <a
          href="#"
          onClick={() =>
            timelineRef?.current
              ? timelineRef.current.scrollIntoView({
                  behavior: "smooth"
                })
              : window.location.replace("/#home")
          }
        >
          Timeline
        </a>
      </nav>
    </div>
  );
});

export default Sidebar;
