import React, { useState, useEffect } from "react";

function Timer() {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => getTime(deadline), 1000);

        return () => clearInterval(interval);
    }, []);

    const deadline = "March, 14, 2024";

    const getTime = () => {
        const time = Date.parse(deadline) - Date.now();

        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
    };
    return (
        <div className="timer">
            <h1 className="timer__heading">It's not far away!!</h1>
            <h2>
                Stay on track with our timer, counting down the remaining days
                for the hackathon.
            </h2>
            <div className="timer__wrapper">
                <div className="timer__wrapper__item">
                    <h1 className="timer__wrapper__item--heading">{days}</h1>
                    <h2>Days</h2>
                </div>

                <div className="timer__wrapper__item">
                    <h1 className="timer__wrapper__item--heading">{hours}</h1>
                    <h2>Hours</h2>
                </div>

                <div className="timer__wrapper__item">
                    <h1 className="timer__wrapper__item--heading">{minutes}</h1>
                    <h2>Minutes</h2>
                </div>
                <div className="timer__wrapper__item">
                    <h1 className="timer__wrapper__item--heading">{seconds}</h1>
                    <h2>Seconds</h2>
                </div>
            </div>
        </div>
    );
}

export default Timer;
