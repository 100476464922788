import React, { useRef } from "react";
import Heart from "../assets/heart";
import trees from "../assets/trees.png";
import { motion } from "framer-motion";
import character from "../assets/last-ninja.png";

const GameOver = React.forwardRef(({ homeRef }) => {
  return (
    <div
      className="game-over__container"
      style={{
        backgroundImage: `url(${trees})`
      }}
    >
      <img src={character} alt="" className="game-over__container--img"/>
      <h1>Start Game</h1>
      <div className="level__container__details--hearts"></div>
      <a
          href="https://sprw.io/stt-t6gGch8qDhoUHPF3rwF64q"
          target="_blank"
          rel="noreferrer"
        >
      <button>Let's Begin</button>
      </a>
    </div>
  );
});

export default GameOver;
