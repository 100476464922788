import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

const ResponsiveSideBar = React.forwardRef(
  ({ aboutRef, timelineRef, homeRef }) => {
    const [toggle, setToggle] = useState(false);

    const menuVars = {
      initial: {
        scaleX: 0
      },

      animate: {
        scaleX: 1
      },

      exit: {
        scaleX: 0
      }
    };
    return (
      <div className="sidebar-responsive-wrapper">
        <AnimatePresence>
          {toggle ? (
            <motion.div
              className="sidebar-responsive origin-right"
              variants={menuVars}
              initial="initial"
              animate="animate"
              exit="exit"
              style={{
                transformOrigin: "right"
              }}
            >
              <nav>
                <a
                  href="#"
                  onClick={() =>
                    homeRef?.current
                      ? homeRef.current.scrollIntoView({
                          behavior: "smooth"
                        })
                      : window.location.replace("/#home")
                  }
                >
                  Home
                </a>
                <a
                  href="#"
                  onClick={() =>
                    aboutRef?.current
                      ? aboutRef.current.scrollIntoView({
                          behavior: "smooth"
                        })
                      : window.location.replace("/#home")
                  }
                >
                  About
                </a>
                <a
                  href="#"
                  onClick={() =>
                    timelineRef?.current
                      ? timelineRef.current.scrollIntoView({
                          behavior: "smooth"
                        })
                      : window.location.replace("/#home")
                  }
                >
                  Timeline
                </a>
              </nav>
              <FaTimes
                onClick={(e) => {
                  e.preventDefault();
                  setToggle(false);
                }}
                className="sidebar-responsive__icon sidebar-responsive__icon-close"
              />
            </motion.div>
          ) : null}
        </AnimatePresence>

        {!toggle ? (
          <FaBars
            onClick={(e) => {
              e.preventDefault();
              setToggle(true);
            }}
            className="sidebar-responsive__icon sidebar-responsive__icon-open"
          />
        ) : null}
      </div>
    );
  }
);

export default ResponsiveSideBar;
