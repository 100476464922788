import React from "react";
import Gold from "../assets/gold.png";
import Silver from "../assets/silver.png";
import Bronze from "../assets/bronze-new.png";

const Prizes = React.forwardRef(() => {
  const prizesValues = [
    {
      id: 1,
      heading: "1st Place",
      prize: "₹ 50,000",
      icon: Gold
    },
    {
      id: 2,
      heading: "2nd Place",
      prize: "₹ 30,000",
      icon: Silver
    },
    {
      id: 3,
      heading: "3rd Place",
      prize: "₹ 20,000",
      icon: Bronze
    }
  ];

  return (
    <div className="prizes">
      <div className="prizes__wrapper">
        <div className="prizes__wrapper--details">
          <h1>Prizes</h1>
          <p>
            Win exciting cash prizes for your innovative solutions and technical
            prowess.
          </p>
        </div>
        <div className="prizes__wrapper--contents">
          {prizesValues.map((prize) => (
            <div key={prize.id} className="prizes__wrapper--content">
              <div className="prizes__wrapper--content--details">
                <h1>{prize.heading}</h1>
                <h3>{prize.prize}</h3>
              </div>
              <img src={prize.icon} alt={prize.heading} />
            </div>
          ))}
        </div>
        <p>Not just that! Extra goodies and vouchers await!</p>
      </div>
    </div>
  );
});

export default Prizes;
